import React, { useReducer, useState } from 'react';
import { styled } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

import styles from './styles.module.scss';

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      const { name, value, day } = action;
      return state.map(item => {
        if (day === item.day) {
          if (name === 'checked') {
            return {
              ...item,
              status: item.status === 'open' ? 'closed' : 'open'
            };
          }
          if (name === 'start') {
            return {
              ...item,
              avl: [
                {
                  ...item.avl[0],
                  hoursStart: value.hoursStart,
                  minsStart: value.minsStart
                }
              ]
            };
          }
          if (name === 'end') {
            return {
              ...item,
              avl: [
                {
                  ...item.avl[0],
                  hoursEnd: value.hoursEnd,
                  minsEnd: value.minsEnd
                }
              ]
            };
          }
          return { ...item, [name]: value };
        }
        return item;
      });
    default:
      return state;
  }
};

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#4CAF50',
    '&:hover': {
      backgroundColor: '#4CAF50'
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#4CAF50'
  }
}));

export const AvailableSlots = ({
  updateWorkingHours,
  timeZone,
  tenantId,
  appartmentId,
  workingHours
}) => {
  const [timeSlot, dispatch] = useReducer(reducer, workingHours);
  const [edit, setEdit] = useState(false);

  const handleUpdate = (e, elem, item) => {
    const { value, day } = item;
    dispatch({ type: 'UPDATE', day: day, value: e.target.value, name: elem });
  };

  const timefromval = (avl, time) => {
    console.log(avl, time);
    let hr = avl ? avl[`hours${time}`] : 0;
    let min = avl ? avl[`mins${time}`] : 0;
    if (hr && hr.toString().length === 1) {
      hr = `0${hr}`;
    }
    if (min && min.toString().length === 1) {
      min = `0${min}`;
    }
    return `${hr === 0 ? '00' : hr}:${min === 0 ? '00' : min}`;
  };

  const splitFun = (e, item, elem) => {
    const { day } = item;
    let time = e.target.value;
    let hr = time.slice(0, 2);
    let min = time.slice(3, 5);
    if (elem === 'start') {
      dispatch({
        type: 'UPDATE',
        day: day,
        value: { hoursStart: hr, minsStart: min },
        name: elem
      });
    }
    if (elem === 'end') {
      dispatch({
        type: 'UPDATE',
        day: day,
        value: { hoursEnd: hr, minsEnd: min },
        name: elem
      });
    }
  };

  const saveTimeHandler = () => {
    const payload = {
      tenantId,
      timezone: timeZone?.value,
      apartmentId: appartmentId,
      workingHours: [...timeSlot]
    };
    updateWorkingHours(payload);
    setEdit(prev => !prev);
  };

  return (
    <div>
      <label className={styles.slotLabel}>
        <AccessTimeIcon />
        Office Hours{' '}
        <div className={styles.editIcon}>
          {!edit ? (
            <EditIcon onClick={() => setEdit(prev => !prev)} />
          ) : (
            <>
              <SaveIcon onClick={saveTimeHandler} style={{ color: '#0009' }} />
              <CloseIcon onClick={() => setEdit(prev => !prev)} />
            </>
          )}
        </div>
      </label>
      {timeSlot.map((item, index) => {
        return (
          <div key={item.day} className={styles.timeSlot}>
            <span className={styles.day}>{days[item.day]}</span>
            <div className={styles.availability}>
              <span className={styles.status}>
                {item.status === 'open' ? 'open' : 'closed'}
              </span>
              {edit ? (
                <GreenSwitch
                  size="small"
                  onChange={e => handleUpdate(e, 'checked', item)}
                  checked={item.status === 'open'}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              ) : (
                ''
              )}
            </div>
            {edit && (
              <div className={styles.timeZones}>
                <div className={styles.startTime}>
                  <TextField
                    id="time"
                    name={'start'}
                    value={timefromval(item.avl[0], 'Start')}
                    type="time"
                    defaultValue="07:30"
                    onChange={e => splitFun(e, item, 'start')}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      step: 300 // 5 min
                    }}
                    sx={{ width: 150 }}
                  />
                </div>
                <div className={styles.endTime}>
                  <TextField
                    id="to"
                    name={'end'}
                    value={timefromval(item.avl[0], 'End')}
                    onChange={e => splitFun(e, item, 'end')}
                    type="time"
                    defaultValue="07:30"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      step: 300 // 5 min
                    }}
                    sx={{ width: 150 }}
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default AvailableSlots;
